















































// CORE
import { Component, Prop, Vue } from 'vue-property-decorator'

// COMPONENTS
import Card from '@/components/cards/Card.vue'
import CaptionCard from '@/components/cards/CaptionCard.vue'

// STORE
import ProfileModule from '@/store/modules/profile'

// INTERFACES
import { UserAuthResource } from '@/store/types'
import { handlePhoneNumber } from '@/utils/functions'

@Component({
  components: {
    Card,
    CaptionCard,
  },
})
export default class CharacterCard extends Vue {
  @Prop({ default: false })
  private isReadonly!: boolean

  @Prop({ default: true })
  private isAutoComplete!: boolean

  @Prop({ default: true })
  private isRequired!: boolean

  @Prop({ default: 0 })
  private formPartId!: number

  @Prop({ default: 'Менеджер' })
  private formPartName!: string

  @Prop({ default: () => ({}) })
  private data!: UserAuthResource

  private get modalOptions (): any {
    return {
      formPartId: this.formPartId,
      formPartName: this.formPartName,
      required: this.isRequired,
      ...this.innerForm,
    }
  }

  private get innerForm (): UserAuthResource {
    return this.data
  }

  private set innerForm (value: UserAuthResource) {
    this.$emit('update:data', {
      ...this.innerForm,
      ...value,
    })
  }

  private get userFullName (): string {
    const name = this.innerForm.name
    const surname = this.innerForm.surname
    const patronymic = this.innerForm.patronymic

    return (name ?? '') + (patronymic ? ' ' + patronymic : '') + (surname ? ' ' + surname : '')
  }

  private get isEmpty (): boolean {
    const name = !!this.innerForm.name?.length
    const surname = !!this.innerForm.surname?.length
    const patronymic = !!this.innerForm.patronymic?.length
    const email = !!this.innerForm.email?.length
    const phone = !!this.innerForm.personalPhone?.length

    return !(name || surname || patronymic || email || phone)
  }

  private get isNotFull (): boolean {
    const name = !!this.innerForm.name?.length
    const surname = !!this.innerForm.surname?.length
    const email = !!this.innerForm.email?.length
    const phone = !!this.innerForm.personalPhone?.length

    return (name || surname || email || phone) && !(name && surname && email && phone)
  }

  private get phoneView (): string | undefined {
    return handlePhoneNumber(
      'view',
      this.innerForm.personalPhone
        ? this.innerForm.personalPhone
        : (this.innerForm as any).phone,
    )
  }

  private get phoneLink (): string | undefined {
    return handlePhoneNumber(
      'link',
      this.innerForm.personalPhone
        ? this.innerForm.personalPhone
        : (this.innerForm as any).phone,
    )
  }

  private mounted () {
    this.$bus.$on(`characterFormChange-${this.formPartId}`, (value: UserAuthResource) => {
      this.innerForm = value
    })

    if (this.isAutoComplete) {
      this.innerForm = ProfileModule.userResource.user
    } else if (this.isReadonly && this.data.name) {
      this.innerForm = this.data
    }
  }
}
